import { useCallback, useEffect } from 'react';

/**
 * This hook auto inits prelines HSStaticMethods and HSTooltip after render
 * dependencies are passed to useEffect
 * @returns functions to manually autoInit
 */
export function usePrelineHs(dependencies: unknown[] = []) {
    const autoInit = useCallback(() => {
        try {
            window.HSStaticMethods?.autoInit();
            window.HSTooltip?.autoInit();
        } catch (err) {
            console.log('PrelineHs autoInit failed:', err);
        }
    }, []);

    useEffect(() => {
        const isBrowser = typeof window !== 'undefined';
        if (isBrowser) {
            import('preline/preline').then(() => {
                setTimeout(autoInit, 20);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...dependencies]);

    return { autoInit };
}
